  (($) ->
    $ ->
    $('#topbar-menu-btn').on 'click', ->
        $('.slide').removeClass('nav-close')
        $('.slide').addClass('nav-open')
        $('.pagetop-link').css('display', 'none')
        $('#topbar-menu-btn').css('display', 'none')
        $('#overlay-menu-close-btn').css('display', 'block')
  ) jQuery
  
  (($) ->
    $ ->
    $('#overlay-menu-close-btn').on 'click', ->
        $('.slide').removeClass('nav-open')
        $('.slide').addClass('nav-close')
        $('.pagetop-link').css('display', 'block')
        $('#topbar-menu-btn').css('display', 'block')
        $('#overlay-menu-close-btn').css('display', 'none')
  ) jQuery
  
  (($) ->
    $ ->
      $header = $('#header')
      $content = $('#content')
      $qa = $('.qa-contents h2')
      $menuHeight = $('#header').height()
      $startPos = 0
      # Nav Fixed
      $(window).scroll ->
        $currentPos = $(this).scrollTop()
        if $currentPos > $startPos
          if $(window).scrollTop() >= 50
            $('#header').css('top', '-100px')
            $('.qa-contents h2').css('position', 'sticky')
        else
          $('#header').css('top', '0px')
          $('.qa-contents h2').css('position', 'relative')
  
        $startPos = $currentPos
  
        if $(window).scrollTop() > $menuHeight
          $header.addClass('fixed')
        else
          $header.removeClass('fixed')
        return
  
      scrollTop = undefined
      return
    return
  ) jQuery
  
  (($) ->
    $ ->
    $('.top-doctor-list-title').on 'click', ->
      $(this).next().slideToggle()
      if $(this).hasClass('is-open')
        $(this).removeClass('is-open')
        $(this).addClass('is-close')
      else
        $(this).removeClass('is-close')
        $(this).addClass('is-open')
      return
    return
  ) jQuery
  